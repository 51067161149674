import React, { lazy } from 'react'




const TimesheetManagementListing = lazy(() =>
  import('./TimesheetManagement/TimesheetManagementListing').then(module => ({
    default: module.TimesheetManagementListing,
  }))
)



const HomeRoutes = [
  {
    props: { exact: true, path: '/TimesheetManagementListing' },
    component: <TimesheetManagementListing />,
  },


]

export default HomeRoutes
